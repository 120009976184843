<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="float" style="width:100vw">
  <div id="a4ac8963">
    <div id="a9ffc7ac">
      <div id="ac45dc8b">
        名称
      </div>
      <el-input id="a12a1bd4" v-model="inp_a12a1bd4">
      </el-input>
    </div>
    <div id="a2c670d4">
      <div id="f109b880">
        图片
      </div>
      <el-upload id="c26db30f" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false" :on-success="updsuc_c26db30f" :before-upload="updbefor_c26db30f">
        <img id="imgc26db30f" v-if="imgurl_c26db30f" :src="imgurl_c26db30f"/>
      </el-upload>
    </div>
    <div id="b91d4839">
      <el-button id="adb245a3" @click="clickadb245a3()">
        保存
      </el-button>
      <el-button id="a17d1b8c" @click="clicka17d1b8c()">
        取消
      </el-button>
    </div>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
export default{
  data(){
    return{
    inp_a12a1bd4:'',
    imgurl_c26db30f:''
      //在此注释下方添加自定义变量
    }
  },
  mounted(){
  },
  computed:{
  },
  watch:{
  },
  methods:{
    updsuc_c26db30f(res,file){
        this.imgurl_c26db30f= URL.createObjectURL(file.raw);
    },
    updbefor_c26db30f(file){
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 20MB!');
        }
        return isJPG && isLt2M;
    },
    async clickadb245a3(){
    },
    async clicka17d1b8c(){
    }
    //在此注释下方添加自定义函数或事件
  }
}
</script>
<style>
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
#a4ac8963{
  width:1649px;
  position:relative;
  padding-top:-155px;
  padding-bottom:781px;
}
#a9ffc7ac{
  width:588px;
  position:relative;
  padding-left:70px;
  padding-top:216px;
}
#ac45dc8b{
  padding-top:13px;
  padding-bottom:13px;
  display:inline-block;
  vertical-align: middle;
}
#ac45dc8b{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#a9ffc7ac .el-input{
  width:436px;
  margin-left:126px;
  display:inline-block;
  vertical-align: middle;
}
#a2c670d4{
  width:282px;
  position:relative;
  margin-top:43px;
  padding-left:70px;
}
#f109b880{
  padding-top:13px;
  padding-bottom:137px;
  display:inline-block;
  vertical-align: middle;
}
#f109b880{
  text-align:left;
  color:#333;
  font-size:13px;
  font-family:PingFang SC;
}
#c26db30f{
  width:130px;
  height:168px;
  margin-left:126px;
  display:inline-block;
  vertical-align: middle;
}
#c26db30f .el-upload{
  position:absolute;
  width:130px;
  height:168px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
#c26db30f .el-upload:hover{
  border-color: #409EFF;
}
 #imgc26db30f{
  width: 178px;  height: 178px;  display: block;}
#c26db30f .iconc26db30f{
  font-size: 28px;  color: #8c939d;  width: 178px;  height: 178px;  line-height: 178px;  text-align: center;}
#b91d4839{
  width:344px;
  position:relative;
  margin-top:60px;
  padding-left:70px;
}
#adb245a3{
  width:142px;
  display:inline-block;
  vertical-align: middle;
}
#adb245a3{
  background-color:rgb(20,22,32,1);
  border-radius:0px 0px 0px 0px;
}
#adb245a3{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
#a17d1b8c{
  width:142px;
  margin-left:60px;
  display:inline-block;
  vertical-align: middle;
}
#a17d1b8c{
  background-color:rgb(153,153,153,1);
  border-radius:0px 0px 0px 0px;
}
#a17d1b8c{
  color:#fff;
  font-size:14px;
  font-family:PingFang SC;
  border:transparent;
}
/*在此注释下方添加自定义样式*/
</style>
